# This script is used on all pages, starting with the new business profile.
# Each page provides its own file and behaviors, and should initialize using
# this event
#
#   $(document).ready(function(){});

App = require('./app')
import "../stylesheets/b/main.less"

# First setup progress loader (PACE) options and load pace.js
window.paceOptions =
  ajax: true
  document: false
  eventLag: false

#dont load pace for ie8
if(!window.dfIE || window.dfIE >=9 )
  pace = require("../../bower_components/pace/pace.min.js")


# This is fired once and applies to all pages on the site.
$(document).ready ->

  # Initialize app
  App.init()

  # Support speech input in the search form.  Works on Chrome.
  searchOnSpeech = (event)->
    if event.target.value.trim()
      event.target.form.submit()
  $(document).on("webkitspeechchange", "#footer-search-form-input", searchOnSpeech)
  $(document).on("speechchange", "#footer-search-form-input", searchOnSpeech)

  # On iOS 6.0 (or later) we open Apple Maps instead of Google Maps.
  iOS = navigator.userAgent.match(/(iPhone|iPod|iPad);.*Version\/(\d+\.\d+) Mobile/)
  iOS6orLater = iOS && parseInt(iOS[2], 10) >= 6
  if iOS6orLater
    $(document).on "click", "a[href*='//maps.google.com']", (event)->
      appleMapsURL = @getAttribute("href").replace("maps.google.com", "maps.apple.com")
      document.location.href = appleMapsURL
      event.preventDefault()


  # On iOS this will hide the address bar
  if iOS && window.scrollY == 0
    window.scrollTo(0, 1)

  return
