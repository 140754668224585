# UI interactions for referral page.

init = ()->

  dataEl       = $("main")
  shareURL     = dataEl.attr("data-url")
  businessName = dataEl.attr("data-name")
  businessId   = $("meta[property='business:id']").attr("content")
  vertical     = $("meta[name='business:vertical']").attr("content")
  emailSubject = encodeURIComponent("Recommended Business: #{businessName}")
  emailBody    = encodeURIComponent("I just had a great experience with #{businessName}, and I recommend their services." +
    "\n\nCheck it out:\n")

  trackEvents = (params)->
    $.ajax
      url: '/c/event/track'
      data:
        'smb_guid': params.smb_guid
        'event_type': params.event_type
        'event_data':
          'event_source': params.event_source
          'event_vertical': params.event_vertical
      type: 'POST'
      dataType: 'JSON'
      success: (response) ->
        console.log 4444
        console.log(JSON.stringify(response))
        return
      error: (jqXHR, textStatus, error) ->
        console.log 555555
        error = new Error("failed to track Event for #{JSON.stringify(params)} status=#{jqXHR.status}")
        error.statusCode = jqXHR.status
        console.log(error)
        return

  # -- Native email client --
  
  $(".refer-email-native").on "click", (event)->
    event.preventDefault()
    params =
      'smb_guid': businessId
      'event_type': 'Refer a friend: share'
      'event_source': 'email'
      'event_vertical': vertical
    trackEvents(params)
    url = "mailto:?subject=#{emailSubject}&body=#{emailBody}#{shareURL}?rs=ne"
    $(".refer-email-native").data("url", url)
    document.location = url

  # -- Web-based email --

  # https://github.com/jacobwg/send-with-webmail/blob/master/scripts/app.js
  # http://www.tomanthony.co.uk/blog/detect-visitor-social-networks/

  $(".refer-gmail").on "click", (event)->
    event.preventDefault()
    params =
      'smb_guid': businessId
      'event_type': 'Refer a friend: share'
      'event_source': 'gmail'
      'event_vertical': vertical
    trackEvents(params)
    url = "https://mail.google.com/mail?view=cm&fs=1&tf=1&su=#{emailSubject}&body=#{emailBody}#{shareURL}?rs=gm"
    window.open(url, "gmail", "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600")

  $(".refer-yahoo").on "click", (event)->
    event.preventDefault()
    params =
      'smb_guid': businessId
      'event_type': 'Refer a friend: share'
      'event_source': 'yahoo'
      'event_vertical': vertical
    trackEvents(params)
    yahooBody = emailBody.replace(/(%0A)+/gi, "%20") # can't have \n or \r in URL
    url = "http://compose.mail.yahoo.com/?Subj=#{emailSubject}&Body=#{yahooBody}#{shareURL}?rs=ym"
    window.open(url, "yahoo", "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=1024")

  $(".refer-outlook").on "click", (event)->
    event.preventDefault()
    params =
      'smb_guid': businessId
      'event_type': 'Refer a friend: share'
      'event_source': 'outlook.com'
      'event_vertical': vertical
    trackEvents(params)
    url = "https://mail.live.com/default.aspx?rru=compose&subject=#{emailSubject}&body=#{emailBody}#{shareURL}?rs=ol"
    window.open(url, "outlook", "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600")


  # -- Facebook --

  # Load FB
  unless document.getElementById("fb-root")
    $("<div>", id: "fb-root").appendTo(document.body)
    script = document.createElement("script")
    script.async = true
    script.src = "//connect.facebook.net/en_US/all.js"
    document.getElementsByTagName("head")[0].appendChild(script)

  # Open FB share dialog (feed or send)
  facebookDialog = (dialog, hash = "")->
    FB.ui
      method:       dialog
      name:         businessName
      link:         shareURL + hash
    , (response)->

  # Button for sharing on Facebook privately (send), opens dialog
  $(".facebook-send").on "click", (event)->
    event.preventDefault()
    params =
      'smb_guid': businessId
      'event_type': 'Refer a friend: share'
      'event_source': 'facebook send'
      'event_vertical': vertical
    trackEvents(params)
    facebookDialog("send", "?rs=fbsend")
    

  # Button for sharing on Facebook timeline, opens dialog
  $(".facebook-share").on "click", (event)->
    event.preventDefault()
    params =
      'smb_guid': businessId
      'event_type': 'Refer a friend: share'
      'event_source': 'facebook wall'
      'event_vertical': vertical
    trackEvents(params)
    facebookDialog("feed", "?rs=fbshare")


  # -- Twitter / Google+ --

  # Button for sharing on Twitter, opens dialog
  $(".twitter-share").on "click", (event)->
    event.preventDefault()
    params =
      'smb_guid': businessId
      'event_type': 'Refer a friend: share'
      'event_source': 'twitter'
      'event_vertical': vertical
    trackEvents(params)
    width   = 550
    height  = 420
    left    = Math.max(Math.round((screen.width / 2) - (width / 2)), 0)
    top     = Math.max(Math.round((screen.height / 2) - (height / 2)), 0)
    text    = encodeURIComponent("I just had a great experience with #{businessName}")
    window.open("https://twitter.com/intent/tweet?url=" + encodeURIComponent(shareURL + "?rs=twitter") + "&text=" + text,
                "intent", "scrollbars=yes,resizable=yes,toolbar=no,location=yes,width=" + width + ",height=" + height + ",left=" + left + ",top=" + top)

  # Button for sharing on Google+, opens dialog
  $(".googleplus-review").on "click", (event)->
    event.preventDefault()
    params =
      'smb_guid': businessId
      'event_type': 'Refer a friend: Google+ review'
      'event_source': 'google+'
      'event_vertical': vertical
    trackEvents(params)
    url = this.href
    window.open(url, "GooglePlus", "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=640,width=960")

  return


module.exports = {
  init: init
}
