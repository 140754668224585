# UI interactions for reviews widget pages.

init = ()->

  readMore = "Read More"
  showLess = "Show Less"
  reformatReview = (reviewContent, moreOrLess ) ->
    html = ''
    paragraphs = reviewContent.split(/\n\s*/)
    displayMoreOrLess =  if moreOrLess==readMore then "<a href='#'>"+ showLess + "</a>" else "... <a href='#'>"+ readMore + "</a>"
    for index, paragraph of paragraphs
      isLastParagraph = (Number(index) ==paragraphs.length-1)
      reviewEle = "<p>" + paragraph + "</p>"
      if (isLastParagraph)
        displayLastParagraph = paragraph + "  "+ displayMoreOrLess
        reviewEle = "<p>" + displayLastParagraph + "</p>"
      html = html + reviewEle
    return html

  $(document).on "click", ".review-body a", ->
    reviewContentEle = $(this).parents(".review-content-show-more")
    reviewContent = reviewContentEle.attr("data-content")
    reviewContentAbbr = reviewContentEle.attr("data-content-abbr")

    if $(this).text()==readMore
      reviewContentEle.html(reformatReview(reviewContent, $(this).text() ))
    else
      reviewContentEle.html(reformatReview(reviewContentAbbr, $(this).text() ))
    return false


module.exports = {
  init: init
}
