init = ()->

  $('#addcalendarbutton').on 'click', ->
    $('#addeventatc1-drop').addClass('addeventatc-selected')


  $('body').on "touchstart mousedown", (e)->
    if($(e.target).attr('id') !='addcalendarbutton' && $(e.target).parents('#addeventatc1-drop').length == 0)
      $('#addeventatc1-drop').removeClass('addeventatc-selected')
      e.preventDefault()


module.exports = {
  init: init
}
