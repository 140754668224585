
// Utilities
var utils = {
  addClass: function(element, className) {
    element.className = element.className + ' ' + className;
  },
  removeClass: function (element, className) {
    var classes = element.className.split(' ');
    element.className = classes.filter(function(value) { return value !== className });
  }
};

// Modal class
function Modal(props) {
  this.modalBody = null;
  this.closeButtons = [];
  this.props = props;
  
  this._onCloseClick = this._onCloseClick.bind(this);
}

// On close click
Modal.prototype._onCloseClick = function (e) {
  e.preventDefault();
  
  this.close();
}

// Build the modal HTML
Modal.prototype._build = function () {
  var closeButton = '<span class="close closeButton">&#10006;</span>';
  var buttons = '<div class="buttons"><button class="close btn inverse pull-left learnMoreButton">Learn more</button><a class="btn pull-right" href="' + this.props.url + '">Let\'s do it!</a></div>';
  var coupon = '<div><img src="/v2/b/images/icon_gift.svg" /><div class="content-inner"><h1>' + this.props.title + '</h1><div class="description">' + this.props.description + '</div></div>' + buttons + '</div>';

  this.modalBody = document.createElement('div');
  this.modalBody.className = "coupon-modal";
  this.modalBody.innerHTML = '<div class="background"></div><div class="content coupon">' + closeButton + coupon + '</div>';
}

// Open the modal and append the page
Modal.prototype.open = function () {
  var self = this;
  
  if (!this.modalBody) {
    this._build();
  }
  
  utils.addClass(document.body, 'modal-open');
  document.body.appendChild(this.modalBody);
  
  // Listen for the close click
  // TODO: Remove jquery here
  // Need to simulate click in tests
  var closeButtons = $('.close');
  
  for (var i = 0; i < closeButtons.length; i++) {
    $(closeButtons[i]).on('click', self._onCloseClick)
    this.closeButtons.push(closeButtons[i])
  }
}

// Close modal
Modal.prototype.close = function () {
  document.body.removeChild(this.modalBody);
  utils.removeClass(document.body, 'modal-open');
  
  this.closeButtons.forEach(function (element) {
    $(element).off('click', self._onCloseClick)
  });
}

module.exports = Modal;
