# code related to availability calendar
# any code thats required by consuming pages should be
# in the corresponding javascript files. This should only
# contain shared code
moment        = require("moment-timezone")
query = require '../utils/query'

init = ()->
  

  # find the first day that has availability and click on it
  selectFirstAvailableDay = ->
    firstAvailableDay = $("section.selected-week .column .date.active").first()
    unless firstAvailableDay.hasClass("selected")
      firstAvailableDay.click()

  manageMobileNav = (weekIndex)->
    if weekIndex > 0  then $(".previous-week").removeClass("disabled")
    if weekIndex < 3  then $(".next-week").removeClass("disabled")
    if weekIndex == 0 then $(".previous-week").addClass("disabled")
    if weekIndex == 3 then $(".next-week").addClass("disabled")
    
  #
  # Change week selection
  #
  # @param weekIndex {Number} - required
  # @param selectFirst {Boolean} - optional
  #  Whether to select the first available day in selected week
  #
  changeWeekSelection = (weekIndex, selectFirst)->
    manageMobileNav(weekIndex)
    $(".selected-week").removeClass("selected-week")
    $("*[data-week=#{weekIndex}]").addClass("selected-week")
    
    if selectFirst
      selectFirstAvailableDay()

    # Let listeners know that the height has (likely) changed
    $(".availability-calendar-main").trigger("heightChange")

  $(document).on "click", ".availability-calendar-main a.weekly-availability", (event)->
    event.preventDefault()
    index = $(this).parent().data("week")
    changeWeekSelection(index, true)

  $(document).on "click", ".next-availability[data-week!=-1] a", (event)->
    event.preventDefault()
    index = $(this).parent().data("week")
    changeWeekSelection(index, true)

  $(document).on "click", ".availability-calendar-main .next-week", (event)->
    event.preventDefault()

    currentWeek = $(this).parent().data("week")
    if currentWeek < 3
      changeWeekSelection(currentWeek + 1, true)

  $(document).on "click", ".availability-calendar-main .previous-week", (event)->
    event.preventDefault()
    currentWeek = $(this).parent().data("week")
    if currentWeek > 0
      changeWeekSelection(currentWeek - 1, true)

  # click on a date that has availability
  $(document).on "click", ".date.active", (event)->
    toggleSelections(event, "selected", ".date.selected")
    #add the selected class on .times
    $('.times.selected').removeClass('selected')
    $(event.currentTarget).parent().find('.times').addClass('selected')
    
  ## Dynamically Bound Event Handlers

  # The issue is that the ready page:load is executed twice when a page is navigated to using a back/forward navigation
  # This is somehow related to turbolinks and the solution below is executed for each page
  # and more of a bandaid to the problem than a real solution.

  # click on a time that has a value
  $(document).on "click", ".availability-calendar-main .times .active", (event)->
    toggleSelections(event, "selected", ".times .selected")
    # raise an event for others to do their thing once the slot is selected
    # if the time slot is deselected(clicked twice), then "" is sent
    timeSelected = $('.time .selected')
    
    data =
      selectedTime: timeSelected.text().trim()
      selectedDate: timeSelected.parents('.column').data("appointment-date")
    $(event.currentTarget).trigger("slotSelection", data)

  ## Shared Functions

  # Manages common element selections, toggles on/off when clicking a element in a class.
  # event - the DOM event
  # className - the name of the class you want to manage
  # target - selector for elements that clearSelection runs on. If absent, runs a className selector on the doc
  toggleSelections = (event, className, target)->
    event.preventDefault()
    # remove all existing selections
    $("#{target}").removeClass(className)
    $(event.currentTarget).addClass(className)
    
  #
  # Select a date and time
  #
  # - Takes the return object from the findDateTime function
  #
  selectDateTime = (found)->
    
    # Change week
    changeWeekSelection(found.weekIndex)
    
    # Select day (add's selected class to data element)
    dateLabel = found.dateElement.children[0]
    dateLabel.className = dateLabel.className + ' selected'
    
    # Select time
    # Set selected class on time
    found.timeElement.className = found.timeElement.className + ' selected'
    
    params =
      type: 'slotSelection'
      selectedTime: found.selectedTime
      selectedDate: found.selectedDate
      
    # trigger data / time selected
    # TODO: Get rid of jquery here
    $(found.timeElement).trigger(params)

  
  #
  # Find parent node of an elemement with a specific tagname
  #
  # - Because it's a step toward removing jQuery from the project...
  #
  findParentNode = (element, tagName)->
    currentElement = element
    found = false
    
    while(currentElement != document && !found)
      
      if currentElement.tagName.toLowerCase() == tagName
        found = true
        return currentElement
      else
        currentElement = currentElement.parentNode
    
    return null
    
  #
  # Find date / time option
  #
  # This function attempts to find a specific day / time in the calendar. If the time is not found, 
  # or is unavailble it returns false. Otherwise it returns a summary with references to the matching date / time elements.
  #
  # - This function grabs all the date values in the calander, then tries to match the date. 
  # - When the date is a match it grabs all the time elements and tries to find a match.
  #
  # @param appointmentDate {string} - "2015-12-10" format
  # @param appointmentTime {string} - "9:30 AM" format
  #
  # @return
  #   - If not found -> {boolean} false
  #   - If found -> {object}
  #       weekIndex: {number} - the index of the week the date/time was found in. Used to switch the ui to that week.
  #       weekIndexElement: {element} - the element that has the week data on it. Used to add classes to show it's active.
  #       dateElement: {element} - the date element that matches the appointmentDate
  #       timeElement: {element} - the time element that matches the appointmentTime
  #       selectedDate: {string} - the matching appointment date formatted to "01 Dec, 2015" style
  #       selectedTime: {string} - the matching appointment time text
  #
  findDateTime = (appointmentDate, appointmentTime)->
    
    # This is formatted to match the data attributes of the date elements
    date = moment(appointmentDate).format('DD MMM, YYYY')
    # Get all dates in calendar
    dates = document.querySelectorAll('div[data-appointment-date]')
    
    for element in dates
      elementDate = element.getAttribute('data-appointment-date')
      
      # Try to match date
      if date == elementDate
        
        # Find the parent element that has the week index data attribute
        weekIndexElement = findParentNode(element, 'section')
        
        # If parent week index element is not found then return false
        if !weekIndexElement
          return false
          
        weekIndex = weekIndexElement.getAttribute('data-week')
        
        # Get all times
        times = document.querySelectorAll("div[data-appointment-date='" + elementDate + "'] .times a.active")
        
        for t in times
          
          # Try to match time
          if appointmentTime == t.textContent
            return {
              weekIndex: weekIndex
              weekIndexElement: weekIndexElement
              
              dateElement: element
              timeElement: t
              
              selectedDate: elementDate
              selectedTime: t.textContent
            }
    
    return false
  
  #
  # Preselect appointment date and time if they are available
  #
  preselect = (appointmentDate, appointmentTime)->
    
    # Attempt to find element
    found = findDateTime(appointmentDate, appointmentTime)
    
    # If not found, trigger alert
    if !found
      # TODO edge-case: if requested date is invalid for some reason, maybe show generic "That date is unavailable", etc
      $('.datetime-warning .unavailable-date').text(moment(appointmentDate).format('MMMM Do'))
      $('.datetime-warning .unavailable-time').text(appointmentTime)
      $('.datetime-warning').removeClass('hidden')
      return
    
    # Select date and time
    selectDateTime(found)


  # everytime the calendar is loaded, this code needs to run
  # this is called on page load, and service change
  load = () ->

    # We start on the current week
    $(".previous-week").addClass("disabled")

    #on mobile, the first day is selected by default
    selectFirstAvailableDay()

    # Highlight current day -> week = 0, day = currentDayIndex
    # need to get the browser timezone so the correct day is highlighted
    currentDay = moment().weekday() + 1 #css selector index starts from 1
    $(".availability-calendar-main section[data-week=0] .column:nth-child(#{currentDay})").addClass('today')

    # add a different style for past days
    # ex) section div:nth-child(-n + 3) selects the first 3 div elements in the section
    # we want to select the first n date and time divs where n is currentDay - 1
    yesterday = currentDay - 1
    $("section[data-week=0] .column:nth-child(-n + #{yesterday}) .date").addClass('past-date')

    # Let listeners know that the height has (likely) changed
    $(".availability-calendar-main").trigger("heightChange")

  # load on page load
  load()
  
  # If query params are set, select defined params
  parsed = query.parseQuery(window.location.search)
  
  if parsed.appointmentDate && parsed.appointmentTime
    preselect(parsed.appointmentDate, parsed.appointmentTime)

  # load on service change
  $(document).on 'serviceChange', load

module.exports = {
  init: init
}
