
require("@bower_components/jquery-validation/jquery.validate.js")
require("@bower_components/jquery-validation/additional-methods.js")
moment        = require("moment-timezone")
helpers = require("../lib/helpers")
parseQuery = require('../utils/query').parseQuery

# Util to update iframe size
iframeSizing = require('../utils/iframe-sizing')

init = ()->
  # If iframe, send changing height to parent
  iframeSizing.init()

  BOOKING_BUFFER = 120 # 2 hour booking buffer
  END_OF_DAY_BUFFER = 30

  appointmentTime = $("#appointment-time")
  booking_flow = $("#book").val()
  formValidated = false
  businessHours  = appointmentTime.data("hours")
  current = moment()
  minutesElapsed = current.hour() * 60 + current.minutes() # minutes elapsed today

  # Convert businessHours to plain old hours, rather then minutes
  # Round and format for time picker
  formatHours = (hour)->
    hour = hour / 60

    if hour % 1 == 0
      return [hour, 0]
    else
      return [Math.floor(hour), 30]

  #
  # Get closed days to disable in calander
  #
  getClosedDays = ()->
    currentDay = current.weekday()
    closedDays = $("#appointment-dates").data("closed-days")

    # Today's business closing time with 2 hour buffer
    latestAvailableRequestTime = businessHours[currentDay][1] - BOOKING_BUFFER - END_OF_DAY_BUFFER

    # If the current time in minutes is greater than
    # the latest bookable time today, then add today's date
    # to the closedDays
    if minutesElapsed >= latestAvailableRequestTime
      closedDays.push([current.year(), current.month(), current.date()])

    return closedDays

  #
  # Get the available appointment request times
  #
  getAvailableTimes = (date)->
    selectedDate = moment(date)
    selectedDay = businessHours[selectedDate.weekday()]
    bookableStart = selectedDay[0]
    bookableEnd = selectedDay[1]

    # By appointment only days are indicated by hours of [-1, -1]. These
    # will allow an appointment request between 8AM (480 mins) and 5PM (1020 mins).  See business model
    # For more information on appointment only.
    if bookableStart + bookableEnd < 0
      bookableStart = 480
      bookableEnd = 1020

    # If selected date is today and the current time is greater than the booking start time
    # Use the current time plus a 2 hour buffer
    if current.isSame(selectedDate, 'day') && minutesElapsed >= bookableStart
      bookableStart = minutesElapsed + BOOKING_BUFFER

    # Add in buffer for end time
    # Only allow a request for the duration of an appoitnment _before_ closing time
    bookableEnd = bookableEnd - END_OF_DAY_BUFFER

    return [formatHours(bookableStart), formatHours(bookableEnd)]

  #
  # Disable time selector - on date change
  #
  disableTimeSelect = ()->
    appointmentTime.prop("disabled", true)
    appointmentTime.val("")
      .attr("placeholder", "First select an appointment date")
  #
  # Enable time selector - on date change
  #
  # Use the pickatime API to set the range of available appointment times according to business hours.
  # To select a new set of available times when selecting a new date,
  # we must reinitialize pickatime on the DOM
  timeInitialized = false

  # default date and time if available
  defaultAppointmentDate = $("#default-appointment-date").val()
  defaultAppointmentTime = $("#default-appointment-time").val()

  enableTimeSelect = (dateValue)->
    appointmentTime.attr("placeholder", "Click here to select an appointment time")
      .removeProp("disabled")

    # Get available times from selected date
    availableTimes = getAvailableTimes(dateValue)

    if timeInitialized
      input = $("#appointment-time").pickatime()
      picker = input.pickatime("picker")
      picker.set({min: availableTimes[0], max: availableTimes[1]})

    else
      # Initialize pickatime
      appointmentTime.pickatime
        min: availableTimes[0]
        max: availableTimes[1]

        # Emit event when time is selected
        onSet: ()->

          appointmentTime.trigger('time-selected')

          # Trigger event on optimizely's version of jQuery
          if optimizely?
            optimizely.$('#appointment-time').trigger('time-selected')

      timeInitialized = true

    if defaultAppointmentTime # prefill appointment time
      $("#appointment-time").pickatime('picker').set('select', defaultAppointmentTime, { format: 'h:i A' })


  phoneNumberCheck = (phone)->
    regExp = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[0-9]{3}[\s\-]?[0-9]{4}$/g;
    return phone.match(regExp) != null

  $.validator.addMethod "phoneWithExtension", (value, element)->
    return phoneNumberCheck(value)

  $.validator.addMethod "notContainsUrl", (value, element)->
    return !(new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(value))

  $.validator.addMethod "validDateFormat", (value, element)->
    if value==''
      return true
    else
      regExp = /(?:(?:0?[1-9])|(?:1[0-2]))\/(?:(?:0?[1-9])|(?:[1-2][0-9])|(?:3[0-1]))\/(?:[0-9]{4})$/;
      return value.match(regExp) != null

  # Set calender
  generatePickADate = ($inputDate, $inputPicker) ->
    if $inputDate.prop "readonly"
      return
    $inputPicker.pickadate
      format: 'mm/dd/yyyy',
      min: true,
      disable: getClosedDays()

    picker = $inputPicker.pickadate('picker')
    inputName = $inputDate.attr('name')
    if $inputDate && $inputPicker && picker
      $inputDate.on
        change: ->
          parsedDate = Date.parse(@value)
          inputName = $inputDate.attr('name')
          if parsedDate
            $("label[for='#{inputName}']").removeClass("error")
            picker.set 'select', parsedDate
            enableTimeSelect(picker.get('value'))
          else
            displayError(inputName)
            if inputName == "appointmentDate"
              disableTimeSelect()
            else if $inputDate.val().trim() =='' #don't show error msg if it is Alternative Date and it's empty
              $("label[for='#{inputName}']").removeClass("error")

            return
        focus: ->
          picker.open false
          return
        blur: ->
          if event?.relatedTarget?.className == "picker__button--clear"
            picker.clear()
            if inputName== "appointmentDate"
              disableTimeSelect()
          if event?.relatedTarget?.className == "picker__button--today"
            picker.set("select", new Date())
          picker.close()
        keydown: (event) ->
          if event.keyCode == 13 || event.keyCode == 9 #if press enter or tab key blur to other fields
            picker.close()
            @blur()
          return

      picker.on 'set', ->
        $("label[for='#{inputName}']").removeClass("error")
        $inputDate.val @get('value')
        if $inputDate.attr("id") == "required-date"
          parseDate = Date.parse(@get('value'))
          if parseDate
            enableTimeSelect(@get('value'))
          else
            disableTimeSelect()
        return
    return

  generatePickADate $('#required-date'), $('#required-date-picker')
  generatePickADate $('#optional-date-1'), $('#optional-date-1-picker')
  generatePickADate $('#optional-date-2'), $('#optional-date-2-picker')

# Lookup timeslots and configure time picker when a date is chosen
  $("#required-date").change ->
    dateValue = $(this).val()
    # Reset time if required date is cleared
    if dateValue == ""
      disableTimeSelect()
    else
      parsedDate = Date.parse(dateValue)
      if parsedDate = Date.parse(dateValue)
        enableTimeSelect(dateValue)
      else
        disableTimeSelect()

  if defaultAppointmentDate? and not booking_flow?  #prefill appointment for pickadate
    $("#required-date-picker").pickadate('picker').set('select', defaultAppointmentDate, { format: 'MM/DD/YYYY' })

  $('#submit').click ->
    $('#appointment-request-form').submit()
    return false
  # Add the necessary class to display an error to the user
  displayError = (name)->
    $("label[for='#{name}']").closest("label").addClass("error")
    if name == "appointmentDate"
      # Display a different error depending on whether or not the availability
      # calendar is showing
      if $("div.scheduling-calendar.hidden").length
        $(".availability-calendar .availability-error").removeClass("hidden")
      else
        $(".scheduling-calendar .requiredDate .tooltip").addClass("error")

  # Validation for schedule appointment form using jquery validation plugin.
  # need to evaluate whether we really need this??
  $("#appointment-request-form").validate
    ignore: []
    onkeyup: false
    onclick: false
    onfocusout: false
    rules:
      "person[firstName]":
        required: true
      "person[lastName]":
        required: true
      "person[phone]":
        required: true
        phoneWithExtension : true
      "person[email]":
        required: true
        email   : true
      appointmentDate:
        required: true,
        validDateFormat: true
      appointmentTime:
        required: true
      petName:
        required: true
      comment:
        notContainsUrl: true
      alternateAppointmentDate1:
        validDateFormat: true
      alternateAppointmentDate2:
        validDateFormat: true
      "g-recaptcha-response":
        required: true

    submitHandler:  (form) ->
      #send a mixpanel event
#      availabilityReturned = isAvailabilityReturned()
#      payload = scheduleTracking
#      #this property needs to be tracked only in the submit event
#      scheduleUI =
#        scheduleUI: if availabilityReturned && !$("#appointment-availability-calendar").hasClass('hidden') then "slots" else "arbitrary"
#      $.extend(payload,scheduleUI)
      #make sure form is only submitted once
      formSubmitted = false
      submitForm = () ->
        if (not $('#service-requested-form')[0]) and $("#service-requested")[0]
          service = document.createElement('input')
          service.type = 'hidden'
          service.name = 'services'
          service.value = $("#service-requested option:selected")[0].value
          form.appendChild(service)



        #
        form.submit()

      submitForm()
    showErrors: (errorsMap, errorsList) =>
      #comes here on submit button click
      formValidated = true
      for field of errorsMap
        displayError(field)
      if errorsList.length>0
        try
          grecaptcha.reset()
          $("label[for='#g-recaptcha-response']").removeClass("error")
        catch error
          console.log "grecaptcha didn't load successful"


  # Do the validation yourself so you have control over what to do when the element is valid
  $("input").on "keyup change", (event) ->
    # Dont do anything the first time. give the user a chance to fill in the all the fields
    if (formValidated)
      attrName = $(this).attr('name')
      if $(this).valid()
        $("label[for='#{attrName}']").removeClass("error")
        #for the availability flow, show an error message near the calendar
        if attrName == "appointmentDate"
          if $("div.scheduling-calendar.hidden").length
            $(".availability-calendar .availability-error").addClass("hidden")
          else
            $(".scheduling-calendar .requiredDate .tooltip").removeClass("error")
      else
        displayError(attrName)

  $("textarea").on "keyup change", (event) ->
    if (formValidated)
      attrName = $(this).attr('name')
      if $(this).valid()
        $("label[for='#{attrName}']").removeClass("error")
      else
        displayError(attrName)

  #
  # On booking calander select
  #
  # - Populate the same input fields used in the original request form
  # - On schedue page it will pass in selected data
  #
  $(document).on "slotSelection", (event)->

    # Appointment schedule page passes the data in
    if event.selectedTime && event.selectedDate
      selectedTime = event.selectedTime
      selectedDate = event.selectedDate
    else
      selectedTime = $(event.target).text().trim()
      selectedDate = $(event.target).parents('.column').data("appointment-date")

    appointmentTime.removeProp("disabled")

    selectedColumn = $(event.target).data("column") # A.K.A. operatory / facility

    if appointmentTime.val() != selectedTime || requiredDate.val() != selectedDate
      appointmentTime.val(selectedTime)
      requiredDate.val(selectedDate)
      appointmentColumn.val(selectedColumn)


    requiredDate.trigger('change')
    appointmentTime.trigger('change')
    appointmentColumn.trigger('change')

  # Revert to the scheduling ui(from the availability calendar ui)
  $('.calendar-message-link').on 'click', (event)->
    event.preventDefault()
    $('.availability-calendar').addClass('hidden')
    $('.scheduling-calendar, form hr').removeClass('hidden')

  requiredDate = $("#required-date")
  appointmentTime = $("#appointment-time")
  appointmentColumn = $("#appointment-column") # A.K.A. operatory / facility

  setRequestAppointmentLink = ->
    # Set link to go to the old schedule page without the calendar if no times are available
    if $("#widget-outer.facebook").length > 0
      requestLink = $("#appointment-request-link").attr("href")
      requestLink += window.location.search
      $('div.next-availability[data-week=-1] a').attr('href', requestLink)
    else
      alias = window.location.pathname.split( '/' )[2]
      requestLink = "/b/#{alias}/schedule/request"
      requestLink += window.location.search
      $('div.next-availability[data-week=-1] a').attr('href',requestLink)

  setRequestAppointmentLink()

  # Mix panel tracking on page load
  isAvailabilityReturned = ->
    return window.df.tracking.availabilityReturned

  availabilityReturned = isAvailabilityReturned()

  scheduleTracking =
    businessId: $("#business-id").val()
    business: $("#business-id").data("business-name")
    pageType: "schedule"
    scheduleType: if df.tracking.bookable then "bookable" else "request"
    availabilityReturned: availabilityReturned
    alpha: df.tracking.alpha
    vertical: $("meta[name='business:vertical']").attr("content")
    source: window.df.tracking.source

  if window.df.tracking.existingCustomer
    scheduleTracking.existingCustomer = window.df.tracking.existingCustomer

#  payload = scheduleTracking

#  if (availabilityReturned)
#    availabilityTracking =
#      slotsVisible : window.df.tracking.slotsVisible
#      daysUntilFirstSlot : window.df.tracking.daysUntilFirstSlot
#      currentIsoWeekday : moment().isoWeekday()

#    $.extend(payload, availabilityTracking)

  $('#service-requested').change (event) ->
    # If we are in alpha, a service dropdown is hidden in the form.  We can set that val to the val selected at the top of the form
    if $('#service-requested-form')[0]
      $('#service-requested-form').val($('#service-requested').val())

    appointmentTime.val("")
    requiredDate.val("")
    $('.availability-table').addClass('csspinner double-up')
    businessId = $("#business-id").val()
    window.df.refreshCalendarAvailability(businessId, this, event)

  $(document).on "serviceChange", ->
    setRequestAppointmentLink()
    $('.availability-table').removeClass('csspinner double-up')

  # populate hidden provider fields on select change
  populateProviderInput = () ->
    selectedProvider = $("#providers").find(":selected")
    $("#providerCode").val(selectedProvider.val())
    $("#providerName").val(selectedProvider.text())

  $("#providers").change ->
    populateProviderInput()

  populateProviderInput()


# handle contact validation on page load if a field is passed in through query params
  queryParameters = parseQuery(window.location.search, true)
  person = ["firstName", "lastName", "email", "phone"] # only validating these fields
  for param of queryParameters
    if param in person
      input = document.querySelector("input[name='person[#{param}]']")
      attrName = $(input).attr('name')
      unless $(input).valid()
        displayError(attrName)


module.exports = {
  init: init
}
