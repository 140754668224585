
var init = function() {
  var profileIBMap=$("#profile-ib-map")
  var containerMap = profileIBMap.parent()
  width=containerMap.outerWidth()
  height=containerMap.outerHeight()
  profileIBMap.attr("src","/e/network-map?businessId="+profileIBMap.data('businessid')+"&width="+width+"px&height="+height+"px&widget=1&zoom=15")
};


module.exports = {
  init: init
};
