# UI interactions for communication preferences page.

init = ()->
  $(".appointment-confirmation-button").click handleAppointmentConfirmationClick
  $(".appointment-confirmation-toast-thankyou").hide();
  $(".appointment-confirmation-toast-error").hide();
  $(".confBtnV2").click handleConfirmationClick
  $(".confErrorMessageV2").hide();
  $(".confThankYouMessageV2").hide();

handleConfirmationClick = ()->
  try
    businessGuid = $(this).data("businessguid")
    appointmentGuid = $(this).data("appointmentguid")
    confirmationSource = $(this).data("confirmationSource")
    $(this).siblings(".appointment-confirmation-loader").css('display', 'flex');
    $(this).hide();
    result = await confirmAppointment businessGuid, appointmentGuid, confirmationSource
    $(this).siblings(".appointment-confirmation-loader").hide()
    $(this).siblings(".confThankYouMessageV2").show()
  catch error
    $(this).hide();
    $(this).siblings(".appointment-confirmation-loader").hide()
    $(this).siblings('.confErrorMessageV2').show();
    console.log error

confirmAppointment = (businessGuid, appointmentGuid, confirmationSource)->
  confirmationUri = "/v2/e/confirm?businessGuid=#{businessGuid}&appointmentGuid=#{appointmentGuid}&confirmationSource=#{confirmationSource}"
  myPromise = new Promise((resolve, reject) ->
    $.ajax
      url: "#{confirmationUri}"
      type: 'PUT'
      success: (result, textStatus, jqXHR) ->
        resolve jqXHR.status
      error: (jqXHR, textStatus, errorThrown) ->
        reject jqXHR.status
  )
  return myPromise

handleAppointmentConfirmationClick = ()->
  try
    businessGuid = $(this).data("businessguid")
    appointmentGuid = $(this).data("appointmentguid")
    confirmationSource = $(this).data("confirmationSource")
    $(this).siblings(".appointment-confirmation-loader").css('display', 'flex');
    $(this).hide();
    result = await confirmAppointment businessGuid, appointmentGuid, confirmationSource
    $(this).siblings(".appointment-confirmation-loader").hide()
    $(this).siblings(".appointment-confirmation-toast-thankyou").show()
  catch error
    $(this).hide();
    $(this).siblings(".appointment-confirmation-loader").hide()
    $(this).siblings('.appointment-confirmation-toast-error').show();
    console.log error

module.exports = {
  init: init
}
