# UI interactions for review pages.
require("../../../bower_components/raty/lib/jquery.raty.js")
require("../../../bower_components/jquery-validation/jquery.validate.js")
helpers = require("../lib/helpers")


init = ()->

  vertical = $("meta[name='business:vertical']").attr("content")
  # -- Star/ratings

  RATING_HINTS = [ "Poor", "Below Average", "Average", "Above Average", "Excellent" ]

  # This method is used to annotate the title of the rating hint with explicit
  # text of the form: "5 stars: Excellent"
  describeScore = (score)->
    hint = RATING_HINTS[score - 1]
    if score == 1
      return "1 star #{hint}"
    else
      return "#{score} stars #{hint}"

  # Accommodate screen readers by updating explicit title for the rating hint.
  clickedRatingStar = (score)->
    $(this).raty("score", score)
    $("#review-stars-hint").attr("title", "Your selected rating: #{describeScore(score)}")

  reviewStars = $("#review-stars")
  # Setup Raty before we can start messing with it.
  setupRaty = () ->
    if !!reviewStars.raty
      reviewStars.raty(
        path:       "/v2/b/images"
        size:       24
        target:     "#review-stars-hint"
        targetKeep: true
        targetText: "None"
        hints:      RATING_HINTS
        scoreName:  "rating"
        click:      clickedRatingStar)
    else
      setTimeout(setupRaty, 100)

  setupRaty()

  $("#review-stars-hint").attr("title", "Your selected rating: None")

  # Accommodate screen readers by adding an ARIA label to each star.
  $("#review-stars img").attr("aria-label", (i)-> describeScore(i + 1))
  # Accommodate keyboard navigation by wrapping each star with an A element,
  # allowing keyboard navigation and selection.
  $("#review-stars img").wrap(-> $("<a>", href: "#") )
  $("#review-stars a").click (event)->
    event.preventDefault()
    $("img", event.target).trigger("click")


  # Setup provider dropdown list
  providerDropdown = $("#providers")
  if providerDropdown?.click
    providerDropdown.click ()->
      metrics =
        pageType: "Leave a Review"
        name:     "Change provider"
      mixpanel.track "Click", metrics


  # -- Validation

  form = $("#review-form")

  # Report that a review was submitted.  If form was valid, jQuery Validate runs
  # the submit handler, and we give the browser 50ms to initiate a request to
  # MixPanel before submitting the form.
  submitHandler = (form)->
    $('.leave-review button').attr("disabled", true) # disable submit button to prevent double submit
    setTimeout () ->
      $('.leave-review button').attr("disabled", false) # enable submit button after 20 secs if for some reason, the user is still on the page
    ,20000
    formSubmitted = false
    submitForm = () ->
      form.submit()

    metrics =
      business:     $(form).attr("data-business-name")
      rating:       $("[name=rating]", form).val()
      nps:          $("[name=nps]:checked", form).val() || "ignored"
      reviewLength: $("[name=body]", form).val().length
      anonymous:    $("[name=anonymous]:checked", form).attr("id") == "post-as-anon"
      vertical:     vertical
      businessId:   $(form).data("business-id")

    provider = undefined

    if $(".leave-review #providers").length > 0
      providerName  = $(".leave-review #providers :selected").text()
      if providerName == "This Business"
        providerName = "(this business)"
      else
        providerName = providerName.trim()
      metrics["provider"] = providerName

    # Track valid submit attempts first
    trackWithTimeout("Click", reviewSubmitMetrics(), 400, ()->
      trackWithTimeout("Review submitted", metrics, 400, submitForm)
    )
    return

  # We show the error in two ways:
  # - Highlight the label for this field by adding the class "error"
  # - Show a tooltip with appropriate error message
  #
  # The label links to the input field by its ID or name(*).
  #
  # Note that the input element created by Raty has a name but no ID attribute.
  showRelevantErrors = (errorsMap)->
    if errorsMap.body
      showError("[name=body]", errorsMap.body)
    else
      hideError("[name=body]")
    if errorsMap.rating
      showError("[name=rating]", errorsMap.rating)
    else
      hideError("[name=rating]")

  showError = (element, message)->
    element   = $(element, form)[0]
    elementID = element.id || element.name
    $("label[for=#{elementID}]").addClass("error")
    $("label[for=#{elementID}] p").text(message)

  hideError = (element, message)->
    element = $(element, form)[0]
    elementID = element.id || element.name
    $("label[for=#{elementID}]").removeClass("error")

  # Track when users attempt to submit a review
  submitAttempts = 0
  trackSubmitAttempts = true

  # Build metrics object from form values for tracking submit attempts.
  # Extracted logic so can be used in both success and invalid handlers in validator.
  reviewSubmitMetrics = () =>
    name:         "Submit"
    pageType:     "Leave a Review"
    business:     $(form).attr("data-business-name")
    rating:       $("[name=rating]", form).val()
    nps:          $("[name=nps]:checked", form).val() || "ignored"
    reviewLength: $("[name=body]", form).val().length
    anonymous:    $("[name=anonymous]:checked", form).attr("id") == "post-as-anon"
    attempts:     submitAttempts++ # Increment attempts for tracking

  # Handler for validator to track invalid submit attempts in Mixpanel
  invalidHandler = ()->
    # Current UI uses validator to show tips even if form is invalid
    # we do not want an event for every ratings click, but
    # `$("#review-stars a").click` handler  below will trigger the invalid
    # handler, so this `trackSubmitAttempts` flag can be used to pause tracking.
    if trackSubmitAttempts
      mixpanel.track "Click", reviewSubmitMetrics()
    return

  # Turn on form validation
  validator = form.validate(
    rules:
      body:
        required:   true
        minlength:  5
        validateSpecialCharactors: $("#review-body").val()
      rating:       "required"
    ignore:         "" # Do not ignore hidden field, especially 'rating'
    showErrors:     showRelevantErrors
    submitHandler:  submitHandler
    invalidHandler: invalidHandler
    messages:
      body:
        required: "Describe in your own words"
        minlength: "Describe in your own words"
        validateSpecialCharactors: "We do not support non-latin characters, html content input, nor preformatted content. Please use plain text or directly type into the text box."
      rating:
        required: "Click on a star to rate us"
  )

  $("#review-stars a").click (event)->
    # The validator is being used to show UI hints,
    # but we do not want to to track this as a submit.
    # We toggle trackSubmitAttempts to false before this one validation
    # call and reset back to true afterwards.
    # This and `trackSubmitAttempts` are uneeded if UI changes around this.
    trackSubmitAttempts = false
    validator.form(this)
    trackSubmitAttempts = true

  # -- Mobile support --

  # Detect mobile device based on media query (same as stylesheets).
  isMobile = window.matchMedia && window.matchMedia("only screen and (max-width: 480px)").matches
  # On mobile devices, we skip the survey and go directly to referral.
  if isMobile
    skipSurveyInputField = $("<input>", type: "hidden", name: "survey", value: "no")
    form.append(skipSurveyInputField)

  # -- View Tracking --
  mixpanel.track "View",
    businessId : $("#review-form").data("business-id")
    business: $("#review-form").data("business-name")
    pageType: "Leave a Review"
    vertical: vertical

module.exports = {
  init: init
}
