
/**
 * Parse URL query
 *
 * Returns an object map of key/value params.
 * E.g. given:
 *     "?a=alpha&b=bravo&c"
 *
 * returns:
 *     {
 *       a: "alpha",
 *       b: "bravo",
 *       c: true
 *     }
 * @param {string} query - The full query string in form of `?param1=val&param2=val2&param3`
 * @param {boolean} raw - If raw is set to true, key only params are returned as is
 * @returns {object} Map of param key/value pairs
 */
function parseQuery(query, raw) {
  var params = query.substr(1).split('&');
  if (params == "")
    return {};

  var parsed = {};
  for (var i = 0; i < params.length; ++i) {
    var p = params[i].split("=", 2);
    if (p.length == 1)
      // Key-only params: `&param`
      if(raw){
        parsed[p[0]] = ""
      }
      else{
        parsed[p[0]] = true
      }

    else
      // Key/value params: `&param=value`
      parsed[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
  }
  return parsed;
}

module.exports = {
  parseQuery: parseQuery
};
