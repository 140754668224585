require("../../../bower_components/jquery-validation/jquery.validate.js")

init = ()->
  formValidated = false

  $('#submit-mileage').click ->
    $('#vehicle-form').submit()

  # Add the necessary class to display an error to the user
  displayError = (name)->
    $("label[for='#{name}']").closest("label").addClass("error")

  $("#vehicle-form").validate
    ignore: []
    onkeyup: false
    onclick: false
    onfocusout: false
    rules:
      "vehicle[mileage]":
        required: true
        number: true
    submitHandler:  (form) ->
      #make sure form is only submitted once
      formSubmitted = false
      form.submit()

    showErrors: (errorsMap, errorsList) =>
      #comes here on submit button click
      formValidated = true
      for field of errorsMap
        displayError(field)

  # Do the validation yourself so you have control over what to do when the element is valid
  $("input").on "keyup change", (event) ->
    if (formValidated)
      attrName = $(this).attr('name')
      integer = /^\d+$/
      isInt = integer.test $(this).val()
      if $(this).valid() && isInt
        $("label[for='#{attrName}']").closest("label").removeClass("error")
      else
        $("label[for='#{attrName}']").closest("label").addClass("error")



module.exports = {
  init: init
}
