require("../../../bower_components/slick/slick.js")

init = ()->

  $(".new-business-profile .nav-items").slick({arrows: true, slidesToShow: 3, slidesToScroll: 1, prevArrow:'.nav .arrow-left', nextArrow: '.nav .arrow-right', infinite: false})

  $(".new-business-profile .nav-item").click (event) ->
    # not sure about the exact behavior yet. This is a placeholder
    $(".new-business-profile .nav-item").removeClass('active')
    $(this).addClass('active')
  
  $(".new-business-profile .offer-items").slick({arrows: true, slidesToShow: 1, slidesToScroll: 1, prevArrow:'.offers .arrow-left', nextArrow: '.offers .arrow-right', infinite: false})

module.exports = {
  init: init
}
