# UI interactions for the text message optin page
require("../../../bower_components/jquery-validation/jquery.validate.js")

PHONE_NUMBER_LENGTH = 13
PHONE_NUMBER_FORMAT = '(000)000-0000'

init = ()->
  phoneInput = $('.phone-input');
  phoneInput.mask(PHONE_NUMBER_FORMAT);

  $('.optin-form').submit (event) ->
    val = phoneInput.val()
    if !val
      event.preventDefault()
      $('.input-error-message').text 'This is a required field.'
    else if val.length < PHONE_NUMBER_LENGTH
      event.preventDefault()
      $('.input-error-message').text 'Please enter a valid phone number.'

module.exports = {
  init: init
}
