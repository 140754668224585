

var query = require('./query');

var referralMap = {
  sp: {
    via: 'social-proof',
    campaignSource: 'Social Proof',
    campaignMedium: 'Referral'
  },
  ne: {
    via: 'email',
    campaignSource: 'Email',
    campaignMedium: 'Referral'
  },
  gm: {
    via: 'gmail',
    campaignSource: 'Gmail',
    campaignMedium: 'Referral'
  },
  ym: {
    via: 'yahoo',
    campaignSource: 'Yahoo mail',
    campaignMedium: 'Referral'
  },
  ol: {
    via: 'outlook.com',
    campaignSource: 'Outlook.com',
    campaignMedium: 'Referral'
  },
  fbshare: {
    via: 'facebook wall',
    campaignSource: 'Facebook Wall',
    campaignMedium: 'Referral'
  },
  fbsend: {
    via: 'facebook send',
    campaignSource: 'Facebook Send',
    campaignMedium: 'Referral'
  },
  twitter: {
    via: 'twitter',
    campaignSource: 'Twitter',
    campaignMedium: 'Referral'
  },
  mint: {
    via: 'mint',
    campaignSource: 'Mint',
    campaignMedium: 'Referral'
  },
  aw: {
    via: 'Google AdWords',
    campaignSource: 'Google AdWords',
    campaignMedium: 'Ad'
  },
  oi: {
    via: 'Opt-Intelligence',
    campaignSource: 'Opt-Intelligence',
    campaignMedium: 'Ad'
  }
};

// Get referral info
function getReferralInfo() {
  var parsed = query.parseQuery(window.location.search);
  
  return referralMap[parsed.rs];
}

module.exports = {
  getReferralInfo: getReferralInfo
}