NetworkCard         = require("./shared/network_module")
Calender            = require("./shared/availability_calendar")
BusinessProfile     = require("./pages/business_profile")
BusinessProfileMaps = require("./pages/business_profile_map")
HopBusinessProfileMaps = require("./pages/v2/business_profile_map")
AppointmentSchedule = require("./pages/appointment_schedule")
AppointmentConfirmation = require("./pages/appointment_confirmation")
Refer               = require("./pages/refer")
TextOptIn           = require("./pages/textoptin")
LeaveReview         = require("./pages/leave_review")
ReviewsWidget         = require("./pages/reviews_widget")
Yelp                = require("./pages/yelp_appointment")
Facebook            = require("./pages/facebook")
NewProfilePage      = require("./pages/new_business_profile")
SmartFeedbackLanding = require("./pages/smart_feedback_landing.coffee")
VehicleMileage      = require("./pages/vehicle")
CommunicationPreferences = require("./pages/communication_preferences")
unsubscribeEmail = require("./pages/unsubscribe_email")
CommunicationPreferencesV2 = require("./pages/v2/communication_preferences")
AppointmentConfirmationV2 = require("./pages/v2/appointment_confirmation")

App =

  init: ()->

    #
    # Network card
    #
    if $(".network-card").length
      NetworkCard.init()


    #
    # Maps on business profile
    #
    if $("main.business-profile").length
      BusinessProfileMaps.init()

    #
    # Maps on business profile
    #
    if $("main.hop-business-profile").length
      HopBusinessProfileMaps.init()

    #
    # Business profile and facebook pages
    #
    if $("main.business-profile").length or $(".facebook .facebook-reviews").length
      BusinessProfile.init()

    #
    # Appointment schedule page
    #
    # Element in shared appointment form
    if $("#appointment-time").length
      AppointmentSchedule.init()

    #
    # Appointment schedule page
    #
    # Element in shared appointment form
    if $("#addcalendarbutton").length
      AppointmentConfirmation.init()

    #
    # Calender
    #
    if $(".availability-calendar-main").length
      Calender.init()


    #
    # Refer page
    #
    if $("main.refer-page").length
      Refer.init()

    #
    # TextOptIn page
    #
    if $("main#textoptin-page").length
      TextOptIn.init()

    #
    # Smart Feedback landing page
    #
    if $("main.smart-feedback-landing").length
      SmartFeedbackLanding.init()

    #
    # Leave review page
    #
    if $("main.leave-review").length
      LeaveReview.init()

    #
    # Yelp page
    #
    if $(".availability-yelp-main").length
      Yelp.init()

    #
    # Facebook page
    #
    if $("#facebook-outer.facebook").length or $("#widget-outer.facebook").length
      Facebook.init()

    #
    # New business profile page
    #
    if $("main.new-business-profile").length
      NewProfilePage.init()
    #
    # Communication Preferences page
    #
    if $(".business-preference-main").length && !$(".business-preference-main-for-hop").length
      CommunicationPreferences.init()

    #
    # unsubscribe email page
    #
    if $(".unsubscribe-main").length
      unsubscribeEmail.init()



    #
    #Hop Communication Preferences page
    #
    if $(".business-preference-main-for-hop").length
      CommunicationPreferencesV2.init()

    if ($("main#update-vehicle").length)
      return VehicleMileage.init()

    if ($("div.business-profile-reviews").length)
      return ReviewsWidget.init()

    #
    # Hop Appointment Confirmation page
    #
    if $(".appointment-confirm-fm").length
      AppointmentConfirmationV2.init()

module.exports = App
