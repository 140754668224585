# UI interactions for business profile page.

ModalController = require('../controllers/referral-modal')
referral        = require('../utils/referral')

init = () ->

  businessId   = $("meta[property='business:id']").attr("content")
  vertical     = $("meta[name='business:vertical']").attr("content")
  
  # Check if this is a referral
  referralInfo = referral.getReferralInfo();
  
  # Initialize referral modal
  if referralInfo
    ModalController.initializeModal();
  

  # -- Navigating between reviews and about views --

  # The business profile page has two views:
  # - Review view is active if there are any reviews to show
  # - About view is active if the URL hash is #about,
  #   or there are no reviews to show
  #
  # If there are no reviews to show, then there is no navigation between the
  # views.
  if $("#business-profile-reviews").length

    # On modern browsers that support pushState, we use that and popstate event
    # to handle navigation between the views.
    #
    # On legacy browsers, we change the location hash instead.  Changing the
    # location hash causes the browser to scroll to that element, or top of page
    # if the hash is empty, which forces us to use #reviews.  Which is why we
    # use pushState where available.

    # Navigation link for showing reviews view
    showReviewsLink = $("#business-profile-navigation-reviews")
    showReviewsLink.click (event)->
      event.preventDefault()
      if history.pushState
        history.pushState(null, null, document.location.href.split("#")[0])
        $(window).triggerHandler("popstate")
      else
        document.location.hash = "reviews"
      return false

    # Navigation link for showing about view
    showAboutLink = $("#business-profile-navigation-about")
    showAboutLink.click (event)->
      event.preventDefault()
      if history.pushState
        history.pushState(null, null, document.location.href.split("#")[0] + "#about")
        $(window).triggerHandler("popstate")
      else
        document.location.hash = "about"
      return false

    # Watch for either hash or pushState changes and update view accordingly.
    $(window).on "hashchange popstate", (event)->
      if document.location.hash == "#about"
        # Show about, hide reviews, enable/disable nav links accordingly
        $("#business-profile-reviews").addClass("hidden").attr("aria-hidden", "true")
        $("#business-profile-about").removeClass("hidden").attr("aria-hidden", null)
        showReviewsLink.removeClass("disabled")
        showAboutLink.addClass("disabled")
      else
        # Show reviews, hide about, enable/disable nav links accordingly
        $("#business-profile-reviews").removeClass("hidden").attr("aria-hidden", null)
        $("#business-profile-about").addClass("hidden").attr("aria-hidden", "true")
        showReviewsLink.addClass("disabled")
        showAboutLink.removeClass("disabled")

    # Pick a view on page load
    $(window).triggerHandler("hashchange")


  # -- Reviews section --

  # Outstanding XHR request to load more reviews
  loadMoreReviews = null
  # Button for loading more reviews
  $(document).on "click", "#business-profile-reviews-load-more button", ->
    button = $(this)
    # Abort any concurrent request
    if loadMoreReviews
      loadMoreReviews.abort()
    # Activity indicator
    button.addClass("disabled")
    loadMoreReviews = $.ajax(button.attr("data-url"))
    loadMoreReviews.done (html)->
      # Take the last review div which contains the button, replace it with all
      # the newly loaded review divs (and possibly another button).
      $("#business-profile-reviews-load-more").replaceWith(html)
      # Let the document know that the height has changed
      $(document).trigger("heightChange")
    .fail ->
      button.removeClass("disabled")
    # Don't propagate
    return false

  $(document).on "click", ".waitlist-popup span, .waitlist-popup .blue-button", ->
    $(".waitlist-popup").hide();
    $(".waitlist-dark-mask").hide();
    return false;

  # Load additional reviews, locate the specific review and put it in focus.
  #
  # linkedReviewId - The element ID, of the form review-<id>
  loadLinkedReview = (linkedReviewId)->
    # Look for the load more button.  We're going to use that URL to request
    # the next batch of reviews.
    loadMoreURL = $("#business-profile-reviews-load-more button").attr("data-url")
    unless loadMoreURL
      return

    # Abort any concurrent request
    if loadMoreReviews
      loadMoreReviews.abort()

    # Load 300 reviews (10 already on page)
    loadMoreReviews = $.ajax(loadMoreURL, data: { limit: 290 })
    loadMoreReviews.done (html)->
      # Take the last review div which contains the button, replace it with all
      # the newly loaded review divs (and possibly another button).
      $("#business-profile-reviews-load-more").replaceWith(html)
      # Try to find the review, if found scroll window to just above review.
      review = document.getElementById(linkedReviewId)
      if review &&  window.scrollY == 0
        offset = $(review).offset()
        # Header is 40px high, plus 10px of spacing
        window.scrollTo(offset.left, offset.top - 50)

  # If URL has #review-<id>, and the page doesn't show a review with that ID,
  # then attempt to load old reviews and scroll to that review.
  linkedReview = document.location.hash.match(/^#(review-[0-9a-fA-F\-]+)$/)
  if linkedReview && !document.getElementById(linkedReview[1])
    loadLinkedReview(linkedReview[1])


  # -- About section --

  # Show more links on about section.
  applyListLimit = (visibleLimit)->
    listClassName = "limit-first-#{visibleLimit}";
    $("." + listClassName).each ->
      listElement = $(this)
      listItemsNotShown = listElement.children().length - visibleLimit
      if listItemsNotShown > 0
        moreLink = $("<a>").attr("href", "#").text(listItemsNotShown + " more...")
        moreLink.click ->
          listElement.removeClass(listClassName)
          moreLink.remove()
          return false
        listElement.after(moreLink)

  applyListLimit(3);
  applyListLimit(5);

  # Mixpanel tracking
  mixpanel = window.mixpanel
  
  # Basic view event
  viewEvent = () ->
    
    # // temporary property - track migration in mixpanel
    migrationMetaTag = document.querySelector("meta[property='business:dfMigration']")
    dfMigration = migrationMetaTag && "true" == migrationMetaTag.getAttribute("content") 

    payload = {
      pageType: "business profile",
      businessId: window.df.constants.businessId,
      vertical: window.df.constants.vertical,
      dfMigration: dfMigration
    };

    # DF-422: measure the before/after effect of changing the canonical to local.demandforce.com
    if window.df.constants.migrateBackToDf
      payload["migration"] = "2016-03"

    # If this is the referral and there is an incentive
    if referralInfo && window.df.referral && window.df.referral.description
      payload.incentive = window.df.referral.description;

    mixpanel.track("View", payload);
  
  # Trigger view event
  viewEvent()

  trackEvents = (params)->
    $.ajax
      url: '/c/event/track'
      data:
        'smb_guid': params.smb_guid
        'event_type': params.event_type
        'event_data':
          'event_source': params.event_source
          'event_vertical': params.event_vertical
      type: 'POST'
      dataType: 'JSON'
      success: (response) ->
        console.log(JSON.stringify(response))
        return
      error: (jqXHR, textStatus, error) ->
        error = new Error("failed to track Event for #{JSON.stringify(params)} status=#{jqXHR.status}")
        error.statusCode = jqXHR.status
        console.log(error)
        return

  # Track inbound referrals
  if referralInfo
    params =
      'smb_guid': businessId
      'event_type': 'Refer a friend: inbound'
      'event_source': referralInfo.via
      'event_vertical': vertical
    trackEvents(params)
    ga("set", campaignMedium: referralInfo.campaignMedium, campaignSource: referralInfo.campaignSource)

module.exports = {
  init: init
}
