# UI interactions for Smart Feedback landing page.
require("../../../bower_components/raty/lib/jquery.raty.js")
helpers = require("../lib/helpers")


init = ()->

  buildTrackMetrics = (name) ->
    metrics =
      businessId : $("#page").data("business-id")
      business: $("#page").data("business-name")
      pageType: "Smart Feedback landing"
      vertical: $("meta[name='business:vertical']").attr("content")

    if name
      metrics.name = name

    return metrics

  # -- View Tracking --
  mixpanel.track "View", buildTrackMetrics()

  $(".experience-choice").hover(onHoverInExperienceChoice, onHoverOutExperienceChoice)

  onHoverInExperienceChoice = () ->
    $(".experience-choice").addClass("unselected")
    $(this).removeClass("unselected")

  onHoverOutExperienceChoice = () ->
    $(".experience-choice").removeClass("unselected")

  $(".good-experience").click ()->
    metrics = buildTrackMetrics("Good experience")
    mixpanel.track "Click", metrics

    reputationBoostActive = $("#page").data("reputation-boost-active")
    googleProfileUrl = $("#page").data("google-profile-url")

    if reputationBoostActive && googleProfileUrl
      $(".experience").hide()
      $(".google-profile-transition").show()
      setTimeout(showGoogleProfile, 2000)
    else
      window.location = getFeedbackUrl()

  $(".poor-experience").click ()->
    metrics = buildTrackMetrics("Poor experience")
    trackWithTimeout "Click", metrics, 400, ()->
      window.location = getSurveyUrl()

  showGoogleProfile = () ->
    googleProfileUrl = $("#page").data("google-profile-url")
    window.location = googleProfileUrl

  getFeedbackUrl = () ->
    return "/e/review/feedback" + window.location.search

  getSurveyUrl = () ->
    return "/e/survey" + window.location.search

module.exports = {
  init: init
}
