#
# Listen for height change event from calender,
# then send updated height to parent via iframe
#
# TODO: Make more generic - pass in widget to monitor
#
init = ()->

  widget = document.getElementById('widget-outer')
  origin = null

  #
  # Send message via iframe
  #
  postMsg = (message, targetOrigin)->
    # post a message only when in a iframe
    if window != window.top && targetOrigin
      window.top.postMessage(message,targetOrigin)

  #
  # Set height of parent window / iframe
  #
  setHeight = (height)->
    postMsg({"height": height}, origin)

  # When Iframe url changes, like in our case,
  # when an appointment is submitted, we wont know the remote website's origin.
  # The only way to know this is to post a message to the iframe, from the Business's website origin
  #
  # As soon as the we receive a message with the target origin,
  # we will post back with the height of our document
  receiveMessage = (event)->
    data = event.data
    if data?.target
      origin = data.target
      # Set initial height
      setHeight(widget.offsetHeight);

      # On height change set parent iframe
      $(document).on "heightChange", () ->
        setHeight(widget.offsetHeight)


  # postMsg({"height":documentHeight}, document.referrer)
  window.addEventListener "message", receiveMessage, false

module.exports = {
  init: init
}
