# UI interactions for communication preferences page.

init = ()->
  businessId = $('input[name="businessId"]').val()
  customerId = $('input[name="customerId"]').val()
  campaignId = $('input[name="campaignId"]').val()
  campaignType = $('input[name="campaignType"]').val()

  $('a.nav').on "click", (event)->
    $('aside').toggleClass 'hiddenAside'
  saveSuccess = "Setting Saved"
  saveError = "Error saving. Try again."
  saveInfo = (status,msg) ->
    $('.email-pre-status').remove()
    $('body').append('<div class="email-pre-status email-pre-' + status+ '"><span>' + msg + '</span></div>')
    $('.email-pre-status').fadeOut(3000)

  $("input[name='address']").focusin ->
    $(this).val($(this).attr("originalValue"))
  $("input[name='address']").focusout ->
    $(this).val($(this).attr("originalValue") + $(this).attr("addressFull"))

  $("input[name='email'], input[name='phone'], input[name='homePhone']").focusin ->
    $(this).val($(this).attr("originalValue"))
  $("input[name='email'], input[name='phone'], input[name='homePhone']").focusout ->
    name = $(this).attr("name")
    newValue = $(this).val()
    if name == "email"
      $(this).val(newValue.replace(/^(\S{2})\S*(\S@\S+)$/g, "$1****$2").replace(/^(\S)\S(@\S+)$/g, "$1*$2"))
    else if name == "phone" || name == "homePhone"
      $(this).val(newValue.replace(/(\d)\d{2}\d{3}(\d{4})/g, "$1xxxxx$2"))

  $("input[name='email'], input[name='phone'], input[name='homePhone'], input[name='address']").change ->
    originalValue = $(this).attr("originalValue")
    originalAddressFull = $(this).attr("addressFull")
    newValue = $(this).val().trim()
    newFullValue = $(this).val().trim()
    name = $(this).attr("name")
    newValueValidated = false

    if name == "email" and /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newValue)
        newValueValidated = true
        newFullValue = newValue
    else if (name == "phone" || name == "homePhone") and newValue.replace(/[\D]/g,"").length > 9
        newValueValidated = true
        newValue = newValue.replace(/[\D]/g,"")
        newFullValue = newValue
    else if name == "address" and newValue.length > 10
        newValueValidated = true
        newFullValue = newValue + originalAddressFull

    else
      saveInfo("error", "Error Saving, invalid " + name)
      if name == "address"
        $(this).val(originalValue + originalAddressFull)
      else
        $(this).val(originalValue)
      return

    if (newValueValidated)
      $.ajax
        url: '/c/customers/update'
        data:
          'businessId': businessId
          'customerId': customerId
          'campaignId': campaignId
          'campaignType': campaignType
          'name': name
          'value': newValue
          'valueFull': newFullValue
        type: 'POST'
        dataType: 'text'
        success: ->
          if name == "address"
            $("input[name='address']").val(newValue + originalAddressFull)
          $("input[name='" + name + "']").attr("originalValue", newValue)
          saveInfo("success", saveSuccess)
          return
        error: ->
          if name == "address"
            $("input[name='address']").val(originalValue + originalAddressFull)
          else
            $("input[name='" + name + "']").val(originalValue)
          saveInfo("error", saveError)
          return

  $('.ep-switch-checkbox').change ->
    type_str = $(this).attr("id")
    optionType = type_str.slice(type_str.lastIndexOf("-")+1)
    endpoint = ""

    if optionType == '19' or optionType == '23'
      optionValue = if $(this).val() >=0 then "-1" else "1"
    else
      optionValue = if $(this).val() == "" || $(this).val() > 0 then "0" else "1"

    if optionType in ['19', '10102', '10103', '10104', '10105', '10107', '10108', '10106']
      endpoint = $("input[name='email']").attr("originalValue")
    else if optionType in ['17', '10202', '10203']
      endpoint = $("input[name='phone']").attr("originalValue")
    else if optionType in ['23', '2301']
      endpoint = $("input[name='homePhone']").attr("originalValue")
    else if optionType in ['21']
      endpoint = $("input[name='address']").attr("originalValue") + $("input[name='address']").attr("addressFull")

    optionOriginalValue = $(this).val()
    $.ajax
      url: '/c/preferences/update'
      data:
        'businessId': businessId
        'customerId': customerId
        'campaignId': campaignId
        'campaignType': campaignType
        'optionType': optionType
        'optionValue': optionValue
        'endpoint': endpoint
      type: 'POST'
      cache:false
      global:false
      dataType: 'text'
      success: ->
        saveInfo 'success', saveSuccess

        if optionType == '19' or optionType == '23' or optionType == '17' or optionType == '21'
          if optionValue <= 0
            $('.options-'+ optionType).addClass 'disabled'
            $('.options-'+ optionType + ' input.ep-switch-checkbox').attr 'disabled','disabled'
          else
            $('.options-'+ optionType).removeClass 'disabled'
            $('.options-'+ optionType + ' input.ep-switch-checkbox').removeAttr 'disabled'

        if optionType == '20'
          $("input[name='email-format']").val optionValue
        else
          $('#onoffep-switch-' + optionType).val optionValue

        return
      error: ->
        if optionType == '20'
          $("input[name='email-format']").val optionOriginalValue
          if optionOriginalValue > 0
            $('#onoffep-switch-text-' + optionType).attr 'checked', true
          else
            $('#onoffep-switch-html-' + optionType).attr 'checked', true

        else
          $('#onoffep-switch-' + optionType).val optionOriginalValue

          if optionOriginalValue > 0 || optionOriginalValue == ""
            $('#onoffep-switch-' + optionType).attr 'checked', true
          else
            $('#onoffep-switch-' + optionType).attr 'checked', false
        saveInfo 'error', saveError
        return


module.exports = {
  init: init
}
