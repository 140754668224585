# UI interactions for communication preferences page.

init = ()->
  businessGuid = $('input[name="businessGuid"]').val()
  consumerGuid = $('input[name="consumerGuid"]').val()
  campaignGuid = $('input[name="campaignGuid"]').val()

  emailAddress = $("input[name='email']").val()
  phoneNumber = $("input[name='phone']").val()
  homePhoneNumber = $("input[name='homePhone']").val()

  emailError = $("#email-section").data("error")
  smsError = $("#text-message-section").data("error")
  voiceError = $("#home-phone-section").data("error")
  if !emailAddress || emailError
    $('#email-section').addClass('disable-section-box').find('input').prop('disabled', true).prop("checked", false);
    $('#onoffep-switch-email').prop("checked", true)
  if !phoneNumber || smsError
    $('#text-message-section').addClass('disable-section-box').find('input').prop('disabled', true).prop("checked", false);
    $('#onoffep-switch-phone').prop("checked", true)
  if !homePhoneNumber || voiceError
    $('#home-phone-section').addClass('disable-section-box').find('input').prop('disabled', true).prop("checked", false);
    $('#onoffep-switch-homePhone').prop("checked", true)

  if emailAddress && emailError
    $('#email-section .error-message').css({'display': 'inline'});
  if phoneNumber && smsError
    $('#text-message-section .error-message').css({'display': 'inline'});
  if homePhoneNumber && voiceError
    $('#home-phone-section .error-message').css({'display': 'inline'});

  $('a.nav').on "click", (event)->
    $('aside').toggleClass 'hiddenAside'
  saveSuccess = "Setting Saved"
  saveError = "Error saving. Try again."
  saveInfo = (status,msg) ->
    $('.email-pre-status').remove()
    $('body').append('<div class="email-pre-status email-pre-' + status+ '"><span>' + msg + '</span></div>')
    $('.email-pre-status').fadeOut(3000)

  $("input[name='address']").focusin ->
    $(this).val($(this).attr("originalValue"))
  $("input[name='address']").focusout ->
    $(this).val($(this).attr("originalValue") + $(this).attr("addressFull"))

  $("input[name='email'], input[name='phone'], input[name='homePhone']").focusin ->
    $(this).val($(this).attr("originalValue"))
  $("input[name='email'], input[name='phone'], input[name='homePhone']").focusout ->
    name = $(this).attr("name")
    newValue = $(this).val()
    if name == "email"
      $(this).val(newValue.replace(/^(\S{2})\S*(\S@\S+)$/g, "$1****$2").replace(/^(\S)\S(@\S+)$/g, "$1*$2"))
    else if name == "phone" || name == "homePhone"
      $(this).val(newValue.replace(/(\d)\d{2}\d{3}(\d{4})/g, "$1xxxxx$2"))

  $("input[name='email'], input[name='phone'], input[name='homePhone'], input[name='address']").change ->
    originalValue = $(this).attr("originalValue")
    originalAddressFull = $(this).attr("addressFull")
    newValue = $(this).val().trim()
    newFullValue = $(this).val().trim()
    name = $(this).attr("name")
    newValueValidated = false

    if name == "email" and /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newValue)
        newValueValidated = true
        newFullValue = newValue
    else if (name == "phone" || name == "homePhone") and newValue.replace(/[\D]/g,"").length > 9
        newValueValidated = true
        newValue = newValue.replace(/[\D]/g,"")
        newFullValue = newValue
    else if name == "address" and newValue.length > 10
        newValueValidated = true
        newFullValue = newValue + originalAddressFull

    else
      saveInfo("error", "Error Saving, invalid " + name)
      if name == "address"
        $(this).val(originalValue + originalAddressFull)
      else
        $(this).val(originalValue)
      return

    if (newValueValidated)
      $.ajax
        url: '/v2/c/customers/update'
        data:
          'businessGuid': businessGuid
          'consumerGuid': consumerGuid
          'campaignGuid': campaignGuid
          'name': name
          'value': newValue
          'valueFull': newFullValue
        type: 'POST'
        dataType: 'text'
        success: ->
          if name == "address"
            $("input[name='address']").val(newValue + originalAddressFull)
          $("input[name='" + name + "']").attr("originalValue", newValue)
          saveInfo("success", saveSuccess)
          return
        error: ->
          if name == "address"
            $("input[name='address']").val(originalValue + originalAddressFull)
          else
            $("input[name='" + name + "']").val(originalValue)
          saveInfo("error", saveError)
          return


  $('.ep-switch-checkbox').change ->
    unsubscribeSwitchChannel = $(this).attr("data-channel")
    optionType = $(this).attr("data-opt-category-id")
    endpoint = ""
    optionValue = if $(this).val() == "" || $(this).val() == "0" then "1" else "0"
    channelBoxEle = $(this).parents(".section-box")
    channelName = channelBoxEle.attr("data-name")
    endpoint = $("input[name='" + channelName + "']").attr("originalValue")

    #comment it here since the postcard address is not supported no. if optionType is postcard address
    #endpoint = $("input[name='address']").attr("originalValue") + $("input[name='address']").attr("addressFull")
    data =
      'businessGuid': businessGuid
      'consumerGuid': consumerGuid
      'campaignGuid': campaignGuid
      "optCategoryId": optionType
      "optStatusId": optionValue,
      'endpoint': endpoint
    if channelName == "email" and optionType == "20"
      data.isEmailFormat = true

    optionOriginalValue = $(this).val()
    $checkedElement = $('#onoffep-switch-' + optionType)
    if unsubscribeSwitchChannel
      $checkedElement = $('#onoffep-switch-' + channelName)
    $.ajax
      url: '/v2/c/preferences/update'
      data: data
      type: 'POST'
      cache:false
      global:false
      dataType: 'text'
      success: ->
        saveInfo 'success', saveSuccess
        if unsubscribeSwitchChannel #click unsubscribe checkbox
          if optionValue == '0'
            $('.options-'+ channelName).addClass 'disabled'
            $('.options-'+ channelName + ' input.ep-switch-checkbox').prop 'disabled', true
          else
            $('.options-'+ channelName).removeClass 'disabled'
            $('.options-'+ channelName + ' input.ep-switch-checkbox').removeAttr 'disabled'
            $('.options-'+ channelName + ' input.ep-switch-checkbox').prop 'disabled', false
          $checkedElement.val optionValue

        else
          if optionType == '20'
            $("input[name='email-format']").val optionValue
          else
            $checkedElement.val optionValue

        return
      error:(e)  ->

        if optionType == '20'
          $("input[name='email-format']").val optionOriginalValue
          if optionOriginalValue == '1'
            $('#onoffep-switch-text-' + channelName).prop 'checked', true
          else
            $('#onoffep-switch-html-' + channelName).prop 'checked', true

        else
          $checkedElement.val optionOriginalValue

          if unsubscribeSwitchChannel
            if optionOriginalValue == '0' || optionOriginalValue == ""
              $checkedElement.prop 'checked', true
            else
              $checkedElement.prop 'checked', false
          else
            if optionOriginalValue == '0' || optionOriginalValue == ""
              $checkedElement.prop 'checked', false
            else
              $checkedElement.prop 'checked', true
        saveInfo 'error', saveError
        return


module.exports = {
  init: init
}
