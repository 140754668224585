
var Modal = require('../components/modal');


// Initialize modal
function initializeModal() {
  var title, description, modal, payload;
  var scheduleUrl = document.querySelector('.business-card-schedule-button a').href;
  var referral = window.df.referral;
  
  // Set title
  if (referral.description)
    title = "A gift is waiting!";
  else
    title = "Don't miss out!";
  
  // Set description
  description = referral.description || "Your friend loves us and thinks you would too.  Schedule an appointment now.";
    
  // Create new model
  modal = new Modal({
    url: scheduleUrl,
    title: title,
    description: description
  })
  
  // Open modal
  modal.open()
  
  // mixpanel tracking
  payload = {
    "pageType": "Saw referral incentive",
    "businessId": window.df.constants.businessId,
    "vertical": window.df.constants.vertical
  };
  if (referral.description) { 
    payload.incentive = referral.description;
  }
  
  window.mixpanel.track("View", payload);
}

module.exports = {
  initializeModal: initializeModal
}