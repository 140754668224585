moment = require("moment-timezone")
helpers = require("../lib/helpers")

init = ()->

  # get the window url
  # page is embedded in an iframe
  if window.location != window.parent.location
    WINDOW_URL = document.referrer
  # page is used standalone (testing or otherwise)
  else
    WINDOW_URL = window.location.href

  # Called when we receive a heightChange event from the calendar widget
  setHeightListener = () ->
    setHeight =
      eventType: "setHeight"
      height: $(".availability-yelp-main").height()
    # Set the height of the iframe for yelp
    top.postMessage(JSON.stringify(setHeight), WINDOW_URL)

  # Trigger this immediately because we've missed the initial setHeight
  # event that came from the availability_calendar
  setHeightListener()

  $(document).on "heightChange", setHeightListener

  businessId =  $(".availability-yelp-main").data("businessid").toString()
  businessName = $(".availability-yelp-main").data("business-name")
  vertical = $("meta[name='business:vertical']").attr("content")
  # track the view event in mixpanel
  trackingData =
    businessId: businessId
    business: businessName
    pageType: "schedule"
    source: "Yelp"
    slotsVisible: window.df.tracking.slotsVisible
    daysUntilFirstSlot: window.df.tracking.daysUntilFirstSlot
    currentIsoWeekday: moment().isoWeekday()
    vertical: vertical

  mixpanel.track("View", trackingData)

 # order payload
  order =
    businessId: businessId
    businessTimezone: $(".availability-yelp-main").data("business-timezone")
    opportunityToken: $(".availability-yelp-main").data("opportunity-token")
    businessName: businessName
    vertical: vertical

  # Show more links languages,services,payments
  showMoreLink = (visibleLimit)->
    listClassName = ".limit-first-#{visibleLimit}"
    $(listClassName).each ->
      listElement = $(this)
      listItemsNotShown = listElement.children().length - visibleLimit
      if listItemsNotShown > 0
        moreLink = $('<a></a>').attr('href', '#').text(" • #{listItemsNotShown} more...")
        moreLink.click ->
          listElement.removeClass("limit-first-#{visibleLimit}")
          moreLink.remove()
          return false
        listElement.append(moreLink)

  showMoreLink(3)

  # create an order in yelp, and redirect to yelp checkout page
  createOrderAndRedirect = (order) ->
    # add mixpanel id to order.
    order.mixpanelId = mixpanel.get_distinct_id()
    # add services to the order
    services = $('.services select')
    if services and services.length > 0
      order.service =
        name: services.val()
        duration: services.find(':selected').data('duration')

    # disable button to prevent double submit
    $('.book-appt-submit').attr("disabled", true)

    showLoading =
      eventType: "displayLoading"

    hideLoading =
      eventType: "hideLoading"

    checkoutRedirect =
      eventType: "checkoutRedirect"

    top.postMessage(JSON.stringify(showLoading), WINDOW_URL)

    success = (yelpOrderId) =>

      redirectToYelp = () ->
        checkoutRedirect.orderID = yelpOrderId
        top.postMessage(JSON.stringify(checkoutRedirect), WINDOW_URL)

      $('.book-appt-submit').attr("disabled", false)
      # track the checkout event in mixpanel
      today = moment.utc().startOf('day')
      # track the checkout event in mixpanel
      trackingData =
        businessId: businessId
        business: businessName
        pageType: "Yelp Checkout"
        source: "Yelp"
        selectedSlotDaysOut: moment(order.appointmentDate).diff(today, 'days')
        selectedSlotTimeOfDay: order.appointmentTime
        selectedSlotDate: order.appointmentDate
        vertical: vertical
      trackWithTimeout( "View", trackingData, 400, redirectToYelp)

    failure = (yelpOrderId) ->
      # hide loading button and show an error
      top.postMessage(JSON.stringify(hideLoading), WINDOW_URL)
      $('.appointment').hide()
      $('.error-message').removeClass('hidden')
      $('.book-appt-submit').attr("disabled", false)


    postRequest =
      type: "POST"
      url: "/p/yelp/order"
      data: order

    # send the order to the server
    $.ajax(postRequest).done(success).fail(failure)

  # called when someone clicks on the appointment slot
  slotSelectionListener = (event, data) ->
    # in mobile, create order immediately, there is no submit button
    # check if form is displayed
    unless ($('.booking-form').is(':visible'))
      order.comment = $("#comment").val()
      order.appointmentTime = data.selectedTime
      order.appointmentDate = data.selectedDate
      order.appointmentColumn = $(event.target).data('column') # A.K.A. operatory / facility
      createOrderAndRedirect(order)

  $(document).on "slotSelection", slotSelectionListener

  validOrder = (order) ->
    return (order.appointmentTime && order.appointmentDate)

  # in desktop, create an order when the user clicks on the submit button
  $('.book-appt-submit').click (event) ->
    event.preventDefault()
    order.comment = $("#comment").val()
    timeSelected = $('.time .selected')
    order.appointmentTime = timeSelected.text().trim()
    order.appointmentDate = timeSelected.parents('.column').data("appointment-date")
    order.appointmentColumn = timeSelected.data('column') # A.K.A. operatory / facility

    if validOrder(order)
      createOrderAndRedirect(order)
    else
      # show error message
      $('.availability-error').removeClass('hidden')

  $('.services select').change (event) ->
    $('.availability-table').addClass('csspinner double-up')
    window.df.refreshCalendarAvailability(businessId, this, event)

module.exports = {
  init: init
}
