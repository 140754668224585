# Sends network module impressions and clicks to MixPanel.
#
# When use views a network module, sends the event `Netmod view` with:
# primaryId   - The ID of the primary business
# businessIds - Array of businesses that are shown in network module
#
# When user clicks a network module, sends the event `Netmod click` with:
# primaryId   - The ID of the primary business
# businessId  - The ID of the clicked business
# business    - The name of the clicked business
# rank        - The rank of the clicked network card (starts at 1)
# primary     - True if clicked on a premium network card

init = ()->

  networkCards = $(".network-card")

  # Identifies the primary business showing on this page
  primaryId = $("meta[property=\"business:id\"]").attr("content")
  # Identifies all the network module business cards
  businessIds = networkCards.toArray().map((card)-> card.getAttribute("data-business-id"))

  # Tell MixPanel which network card businesses are shown on the page of this
  # primary business.
  mixpanel.track("Netmod view",
    primaryId:   primaryId
    businessIds: businessIds
    vertical: $("meta[name='business:vertical']").attr("content")
    pageType: $(".network-module").data("page-type")
  )

  # Track links on any of the network module.
  $(".network-card a").click (event)->
    # We need to delay the page transition slightly, so we get a chance to send
    # data over to MixPanel.
    event.preventDefault()
    href = this.getAttribute("href")
    setTimeout ->
      window.location.href = href
    , mixpanel.config.track_links_timeout

    # Collect data from the network card and send it over to MixPanel.
    card = $(this).closest(".network-card")
    properties =
      primaryId:  primaryId
      rank:       card.attr("data-rank")
      premium:    card.hasClass("network-card-premium")
      business:   card.attr("data-business")
      businessId: card.attr("data-business-id")
      vertical:   $("meta[name='business:vertical']").attr("content")
      pageType:   $(".network-module").data("page-type")
    mixpanel.track("Netmod click", properties)

module.exports = {
  init: init
}
