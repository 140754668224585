# Accepts a number or string, and adds commas to separate thousands, millions,
# etc (e.g. 56789 becomes 56,789).  Defaults to zero places after the decimal
# digit.
$.thousands = (value = 0, places = 0)->
  [integer, fraction] = (value || 0).toString().split(".")
  integer = (integer || "0").replace(/^(\d+?)((\d{3})+)$/g, (x,a,b)-> a + b.replace(/(\d{3})/g, ",$1") )
  return integer if places == 0
  fraction ||= "0"
  fraction = fraction.substring(0, places) if fraction.length > places
  fraction += new Array(places - fraction.length + 1).join("0") if fraction.length < places
  fraction = fraction.replace(/^((\d{3})+)(\d+)$/g, (x,a,b,c)-> a.replace(/(\d{3})/g, "$1,") + c )
  return "#{integer}.#{fraction}"

$.fn.liveFilter = (selector, container)->
  $items = $(selector)
  $container = $(container)
  self = this

  iterate = =>
    shown = $items.length
    if self.val() == ""
      $container.removeClass "no-results"
      return $items.show()
    $items.each ->
      $this = $(this)
      if new RegExp(self.val(), "gi").test($this.text())
        $this.show()
      else
        shown--
        $this.hide()
    if shown == 0
      $container.addClass "no-results"
    else
      $container.removeClass "no-results"

  keyUpTimeout = ""
  this.bind "keyup", (event) =>
    if [33,34,37,38,39,40].indexOf(event.keyCode) == -1
      clearTimeout keyUpTimeout
      keyUpTimeout = setTimeout iterate, 200

# call mixpanel.track with the event name and payload
# invoke the callback regardless, if mixpanel doesnt get back
# within 'timeout' seconds
window.trackWithTimeout = (name, payload, timeout, callback) ->
  mixpanel.track name, payload, () ->
    # remove the timeout if tracking is done before timeout expires
    if !done
      done = true
      clearTimeout(timeoutId) if timeoutId
      callback()
  # wait and invoke callback if not already invoked
  timeoutId = setTimeout(callback, timeout) if !done
  done = true

window.df = window.df || {}

# Given the businessId and the serviceDropdown element, refresh the calendar
# widget with times based on the service selected
window.df.refreshCalendarAvailability = (businessId, serviceDropdown, event) ->
  service = $(serviceDropdown).val()
  duration =  $(serviceDropdown).find(':selected').data('duration')

  url = "/widgets/#{businessId}/calendar?service=#{encodeURIComponent(service)}"
  if duration
    url = "#{url}&duration=#{duration}"
  getRequest =
    type: "GET"
    url: url
    dataType: 'html'
  success = (html) ->
    $('.availability-calendar-main').replaceWith(html)
    # the calendar can do what it needs to do
    $(event.currentTarget).trigger("serviceChange", {service: service, duration: duration})
  failure = (error) ->
    # what can we do here ?
  $.ajax(getRequest).done(success).fail(failure)
