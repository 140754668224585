URL = require("url")

init = ()->

  setHeight = (height)->
    try
      FB.Canvas.setSize({ height: height })
    catch error #FB is fickle, just swallow the errors
      return

  $(document).on "heightChange", () ->
    setHeight document.querySelector("#facebook-outer, #widget-outer").offsetHeight + 1

  # For whatever reason there seems to be a race condition that ends up with the iframe not quite sized appropriately
  # This should trigger a height change a second after that page has loaded
  # If nothings, changed, the user won't see anything.  Otherwise the height will adjust to the correct size
  window.setTimeout( ()->
    $(document).trigger "heightChange"
    , 2500)

  $("#loginForm").submit (event)->
    postData = $(this).serialize()
    formURL = $(this).attr('action')

    params =
      url: formURL
      type: "POST"
      data: postData
      success: (response) ->
        window.location = response.url
        return

      error: (jqXHR, textStatus, error) ->
        $(".errorMessage").text(jqXHR.responseJSON.message)
        $(document).trigger("heightChange")

    $.ajax(params)

    event.preventDefault()
    return

module.exports = {
  init: init
}
